exports.components = {
  "component---src-pages-1-bhk-east-type-2-js": () => import("./../../../src/pages/1bhk_east_type2.js" /* webpackChunkName: "component---src-pages-1-bhk-east-type-2-js" */),
  "component---src-pages-1-bhk-west-type-1-js": () => import("./../../../src/pages/1bhk_west_type1.js" /* webpackChunkName: "component---src-pages-1-bhk-west-type-1-js" */),
  "component---src-pages-2-bhk-east-type-1-js": () => import("./../../../src/pages/2bhk_east_type1.js" /* webpackChunkName: "component---src-pages-2-bhk-east-type-1-js" */),
  "component---src-pages-2-bhk-east-type-2-js": () => import("./../../../src/pages/2bhk_east_type2.js" /* webpackChunkName: "component---src-pages-2-bhk-east-type-2-js" */),
  "component---src-pages-2-bhk-east-type-3-js": () => import("./../../../src/pages/2bhk_east_type3.js" /* webpackChunkName: "component---src-pages-2-bhk-east-type-3-js" */),
  "component---src-pages-2-bhk-north-type-1-js": () => import("./../../../src/pages/2bhk_north_type1.js" /* webpackChunkName: "component---src-pages-2-bhk-north-type-1-js" */),
  "component---src-pages-2-bhk-north-type-2-js": () => import("./../../../src/pages/2bhk_north_type2.js" /* webpackChunkName: "component---src-pages-2-bhk-north-type-2-js" */),
  "component---src-pages-2-bhk-west-type-1-js": () => import("./../../../src/pages/2bhk_west_type1.js" /* webpackChunkName: "component---src-pages-2-bhk-west-type-1-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-amber-js": () => import("./../../../src/pages/amber.js" /* webpackChunkName: "component---src-pages-amber-js" */),
  "component---src-pages-aurum-js": () => import("./../../../src/pages/aurum.js" /* webpackChunkName: "component---src-pages-aurum-js" */),
  "component---src-pages-brochure-js": () => import("./../../../src/pages/brochure.js" /* webpackChunkName: "component---src-pages-brochure-js" */),
  "component---src-pages-brochure-thankyou-js": () => import("./../../../src/pages/brochure_thankyou.js" /* webpackChunkName: "component---src-pages-brochure-thankyou-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contactus-thankyou-js": () => import("./../../../src/pages/contactus_thankyou.js" /* webpackChunkName: "component---src-pages-contactus-thankyou-js" */),
  "component---src-pages-emi-js": () => import("./../../../src/pages/emi.js" /* webpackChunkName: "component---src-pages-emi-js" */),
  "component---src-pages-enquiry-thankyou-js": () => import("./../../../src/pages/enquiry_thankyou.js" /* webpackChunkName: "component---src-pages-enquiry-thankyou-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-key-plan-js": () => import("./../../../src/pages/key_plan.js" /* webpackChunkName: "component---src-pages-key-plan-js" */),
  "component---src-pages-nri-brochure-thankyou-js": () => import("./../../../src/pages/nri_brochure_thankyou.js" /* webpackChunkName: "component---src-pages-nri-brochure-thankyou-js" */),
  "component---src-pages-nri-js": () => import("./../../../src/pages/nri.js" /* webpackChunkName: "component---src-pages-nri-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy_policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refer-js": () => import("./../../../src/pages/refer.js" /* webpackChunkName: "component---src-pages-refer-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */)
}

